import { render, staticRenderFns } from "./default.vue?vue&type=template&id=240cbda0&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderMobile: require('/usr/src/nuxt-app/components/layout/header/HeaderMobile.vue').default,LeftAside: require('/usr/src/nuxt-app/components/layout/aside/LeftAside.vue').default,MainHeader: require('/usr/src/nuxt-app/components/layout/header/MainHeader.vue').default,ScrollTop: require('/usr/src/nuxt-app/components/layout/extras/ScrollTop.vue').default})
