
import { mapGetters } from 'vuex'
import KTLayoutHeaderTopBar from '@/assets/js/layout/base/header-topbar.js'

export default {
  name: 'HeaderMobile',
  components: {},
  computed: {
    ...mapGetters({
      layoutConfig: 'config/layoutConfig',
      getClasses: 'htmlclass/getClasses',
    }),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return this.layoutConfig('self.logo.dark')
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses('header_mobile')
      if (typeof classes !== 'undefined') {
        return classes.join(' ')
      }
      return null
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig('aside.self.display')
    },
  },
  mounted() {
    KTLayoutHeaderTopBar.init(this.$refs.kt_header_mobile_topbar_toggle)
  },
}
