
export default {
  name: 'ErrorPage',
  layout: 'blank',
  props: {
    error: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    backgroundImage() {
      return require('~/assets/media/error/bg6.jpg')
    },
  },
}
