export const PERMISSION = 'Permission'
export const ROLE = 'Role'
export const USER = 'User'
export const LESSON = 'Lesson'
export const CHARACTER = 'Character'
export const CATEGORY = 'Category'
export const SPATIAL = 'Spatial'
export const SELF_REVIEW = 'SelfReview'
export const PAGE = 'Page'
export const SPATIAL_VARIANT = 'SpatialVariant'
