
import { mapGetters } from 'vuex'
import objectPath from 'object-path'
import KTLayoutBrand from '@/assets/js/layout/base/brand.js'
import KTLayoutAsideToggle from '@/assets/js/layout/base/aside-toggle.js'

export default {
  name: 'MainBrand',
  computed: {
    ...mapGetters({
      layoutConfig: 'config/layoutConfig',
    }),

    allowMinimize() {
      return !!this.layoutConfig('aside.self.minimize.toggle')
    },
  },
  mounted() {
    // Init Brand Panel For Logo
    KTLayoutBrand.init(this.$refs.kt_brand)

    // Init Aside Menu Toggle
    KTLayoutAsideToggle.init(this.$refs.kt_aside_toggle)
  },
  methods: {
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig('brand.self.theme')
      // set brand logo
      const logoObject = this.layoutConfig('self.logo')

      let logo
      if (typeof logoObject === 'string') {
        logo = logoObject
      }
      if (typeof logoObject === 'object') {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + '')
      }
      if (typeof logo === 'undefined') {
        const logos = this.layoutConfig('self.logo')
        logo = logos[Object.keys(logos)[0]]
      }
      return logo
    },
  },
}
