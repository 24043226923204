
import KTLayoutQuickUser from '@/assets/js/layout/extended/quick-user.js'
import KTOffcanvas from '@/assets/js/components/offcanvas.js'

export default {
  name: 'KTQuickUser',
  mounted() {
    KTLayoutQuickUser.init(this.$refs.kt_quick_user)
  },
  methods: {
    async onLogout() {
      await this.$auth.logout()
      await this.$router.push('/login')
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide()
    },
  },
}
