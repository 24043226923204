
export default {
  name: 'DropdownNotification',
  data() {
    return {
      tabIndex: 0,
      list1: [
        {
          title: 'Briviba SaaS',
          desc: 'PHP, SQLite, Artisan CLIмм',
          color: 'primary',
          svg: require('~/assets/media/svg/icons/Home/Library.svg'),
        },
        {
          title: 'Briviba SaaS',
          desc: 'PHP, SQLite, Artisan CLIмм',
          color: 'warning',
          svg: require('~/assets/media/svg/icons/Communication/Write.svg'),
        },
        {
          title: 'Briviba SaaS',
          desc: 'PHP, SQLite, Artisan CLIмм',
          color: 'success',
          svg: require('~/assets/media/svg/icons/Communication/Group-chat.svg'),
        },
        {
          title: 'Briviba SaaS',
          desc: 'PHP, SQLite, Artisan CLIмм',
          color: 'danger',
          svg: require('~/assets/media/svg/icons/General/Attachment2.svg'),
        },
        {
          title: 'Briviba SaaS',
          desc: 'PHP, SQLite, Artisan CLIмм',
          color: 'info',
          svg: require('~/assets/media/svg/icons/Communication/Shield-user.svg'),
        },
        {
          title: 'Briviba SaaS',
          desc: 'PHP, SQLite, Artisan CLIмм',
          color: 'info',
          svg: require('~/assets/media/svg/icons/Communication/Mail-notification.svg'),
        },
        {
          title: 'Briviba SaaS',
          desc: 'PHP, SQLite, Artisan CLIмм',
          color: 'info',
          svg: require('~/assets/media/svg/icons/Design/Bucket.svg'),
        },
      ],
      list2: [
        {
          title: 'New report has been received',
          desc: '23 hrs ago',
          icon: 'flaticon2-line-chart text-success',
        },
        {
          title: 'Finance report has been generated',
          desc: '25 hrs ago',
          icon: 'flaticon2-paper-plane text-danger',
        },
        {
          title: 'New order has been received',
          desc: '2 hrs ago',
          icon: 'flaticon2-user flaticon2-line- text-success',
        },
        {
          title: 'New customer is registered',
          desc: '3 hrs ago',
          icon: 'flaticon2-pin text-primary',
        },
        {
          title: 'Application has been approved',
          desc: '3 hrs ago',
          icon: 'flaticon2-sms text-danger',
        },
        {
          title: 'New file has been uploaded',
          desc: '5 hrs ago',
          icon: 'flaticon2-pie-chart-3 text-warning',
        },
        {
          title: 'New user feedback received',
          desc: '8 hrs ago',
          icon: 'flaticon-pie-chart-1 text-info',
        },
        {
          title: 'System reboot has been successfully completed',
          desc: '12 hrs ago',
          icon: 'flaticon2-settings text-success',
        },
        {
          title: 'New order has been placed',
          desc: '15 hrs ago',
          icon: 'flaticon-safe-shield-protection text-primary',
        },
        {
          title: 'Company meeting canceled',
          desc: '19 hrs ago',
          icon: 'flaticon2-notification text-primary',
        },
        {
          title: 'New report has been received',
          desc: '23 hrs ago',
          icon: 'flaticon2-fax text-success',
        },
        {
          title: 'Finance report has been generated',
          desc: '25 hrs ago',
          icon: 'flaticon-download-1 text-danger',
        },
      ],
    }
  },
  computed: {
    backgroundImage() {
      return require('~/assets/media/misc/bg-1.jpg')
    },
  },
  methods: {
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]')
      const links = tab.querySelectorAll('.nav-link')
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove('active')
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute('data-tab'))

      // set current active tab
      event.target.classList.add('active')
    },
  },
}
